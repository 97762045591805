<template>
  <div class="d-flex flex-column flex-root">
    <b-row class="m-0 pa-0">
      <b-col sm="0" md="1" lg="1" xl="1" class="m-0 pa-0"></b-col>
      <b-col sm="12" md="10" lg="10" xl="10" class="m-0 pa-0">
        <!-- begin:: Header Mobile -->
        <KTHeaderMobile></KTHeaderMobile>
        <!-- end:: Header Mobile -->
        <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

        <!-- begin::Body -->
        <div class="d-flex flex-row flex-column-fluid page">
          <div id="kt_wrapper"
            class="d-flex flex-column flex-row-fluid wrapper pt-10 pt-sm-14 pt-md-14 pt-lg-14 pt-xl-14"
            style="padding-left: 0px!important;">
            <!-- begin:: Header -->
            <KTHeader class="market-padding" style="left: 0!important;padding: 0 200px 0 200px"></KTHeader>
            <!-- end:: Header -->

            <!-- begin:: Content -->
            <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
              <!-- begin:: Content Head -->

              <!-- begin:: Content Body -->
              <div class="d-flex flex-column-fluid">
                <div :class="{ 'container-fluid': contentFluid, container: !contentFluid }">
                  <transition name="fade-in-up">
                    <router-view />
                  </transition>
                </div>
              </div>

            </div>
            <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
            <KTScrollTop></KTScrollTop>
          </div>
        </div>
      </b-col>
      <b-col sm="0" md="1" lg="1" xl="1" class="m-0 pa-0"></b-col>
    </b-row>
    <KTFooter></KTFooter>
  </div>
</template>

<script>
  import {
    mapGetters
  } from "vuex";
  import KTHeader from "@/view/layout/header/Header.vue";
  import KTFooter from "@/view/layout/footer/Footer.vue";
  import HtmlClass from "@/core/services/htmlclass.service";
  import KTScrollTop from "@/view/layout/extras/ScrollTop";
  import KTHeaderMobile from "@/view/layout/header/HeaderMobile";

  import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
  import Loader from "@/view/content/Loader.vue";
  import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME
  } from "@/core/services/store/htmlclass.module.js";

  export default {
    name: "HomeLayout",
    components: {
      KTHeader,
      KTFooter,
      KTStickyToolbar,
      KTScrollTop,
      Loader,
      KTHeaderMobile
    },
    data() {
      return {
        headClass: ""
      };
    },
    beforeMount() {
      // show page loading
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      // initialize html element classes
      HtmlClass.init(this.layoutConfig());
    },
    mounted() {
      // check if current user is authenticated
      if (!this.isAuthenticated) {
        //this.$router.push({name: "login"});
      }

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 2000);
    },
    methods: {},
    computed: {
      ...mapGetters([
        "isAuthenticated",
        "breadcrumbs",
        "pageTitle",
        "layoutConfig"
      ]),

      /**
       * Check if the page loader is enabled
       * @returns {boolean}
       */
      loaderEnabled() {
        return !/false/.test(this.layoutConfig("loader.type"));
      },

      /**
       * Check if container width is fluid
       * @returns {boolean}
       */
      contentFluid() {
        return this.layoutConfig("content.width") === "fluid";
      },

      /**
       * Page loader logo image using require() function
       * @returns {string}
       */
      loaderLogo() {
        return process.env.BASE_URL + this.layoutConfig("loader.logo");
      },

      /**
       * Set the right toolbar display
       * @returns {boolean}
       */
      toolbarDisplay() {
        // return !!this.layoutConfig("toolbar.display");
        return true;
      },

      /**
       * Set the subheader display
       * @returns {boolean}
       */
      subheaderDisplay() {
        return !!this.layoutConfig("subheader.display");
      }
    }
  };
</script>

<style>
  market-padding {
    left: 0 !important;
  }
</style>